import React from 'react';
import { Tooltip } from 'react-tooltip';

import { generateRandomId } from '../../utils/randomUtils';

export default function Shape({ type, size, children, active, action, label, extraClass, place = 'top' }: Props) {
    const randomId = generateRandomId(10);

    const generateStyling = (): StylingObject => {
        let result: StylingObject = {};

        if (size) {
            result.height = size + "px";
            result.width = size + "px";
        }

        return result;
    }

    return (
        <div
            className={`shape-container ${type} ${extraClass ?? ''} ${action ? 'cursor-pointer' : ''} ${active ? 'active' : ''}`}
            style={generateStyling()}
            data-tooltip-id={randomId}
            data-tooltip-content={label}
            onClick={action}
        >
            <div className="border">
                <div className="content">
                    {children}
                </div>
            </div>
            <Tooltip id={randomId} place={place} />
        </div>
    );
}

type Props = {
    type: string;
    size?: number;
    order?: number;
    children?: any;
    active?: boolean;
    action?: (() => void);
    label?: string;
    extraClass?: string;
    place?: PlacesType;
};

type PlacesType =
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end';

type StylingObject = {
    'height'?: string;
    'width'?: string;
};