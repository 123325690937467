import React from 'react';

import Shape from '../common/Shape';
import Title from '../common/Title';

export default function Description() {

    return (
        <div className="description">
            <Shape type={"octogon"}>
                <Title text={"Professional"} withLines={true} size={"md"} />
                <p className="fade-in-scale-up with-delay">
                    Hey there! I'm Sebastien Bacs, a passionate full-stack developer with five years of experience in the ever-thrilling
                    world of coding. What drives me? Well, it's not just the lines of code! It's the collaboration, the collective brainstorming,
                    and the constant quest to learn and grow alongside incredible colleagues.
                    <br /><br />
                    I'm that developer who's always up for a great conversation, eager to share ideas, and thrilled to dive into the latest programming
                    tech. For me, the beauty of this field isn't just in what I know is, it's in the endless possibilities of what I can learn and create next!
                    <br /><br />
                    If you want to know more, let's have a chat!
                </p>
                <Shape type={"triangle"} size={30}></Shape>
            </Shape>
            <Shape type={"octogon"}>
                <Title text={"Personal"} withLines={true} size={"md"} />
                <p className="fade-in-scale-up with-delay">
                    As a Belgian adventurer fueled by curiosity and connection, you'll find me savoring a delightful Belgian beer, engaging in lively discussions,
                    and seeking out the next thrilling travel escapade.
                    <br /><br />
                    Travel is my passion, and I've traversed the landscapes of Australia, reveled in the sun-soaked vibes of California, and soaked up the rich
                    culture of Singapore. Each journey adds to my tapestry of experiences and fuels my wanderlust for more.
                    <br /><br />
                    So, whether it's swapping stories over a pint, exploring new horizons, or simply sharing a beat, I'm always eager to dive into new experiences
                    and connect with fellow adventurers!
                </p>
                <Shape type={"triangle"} size={30}></Shape>
            </Shape>
        </div>
    );
}