import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faGamepad, faEarthEurope, faDiceD20, faVideo, faUtensils, faMusic } from '@fortawesome/free-solid-svg-icons';

import Shape from '../common/Shape.tsx';
import Rotation from '../common/Rotation.tsx';

export default function Hobbies() {
    const [selectedHobby, setSelectedHobby]: any = useState({ "description": "Click on any passion that makes me, ME !" });

    const hobbiesObject: HobbyObject = {
        "video": {
            "icon": faVideo,
            "description": "I watch a lot of streams, movies, series and youtube videos in my free time, usually about games.",
            "color": "orange"
        },
        "gaming": {
            "icon": faGamepad,
            "description": "I love to play online games with my friends! I usually play games like: League of Legends, Overwatch, Minecraft.",
            "color": "blue"
        },
        "roleplay": {
            "icon": faDiceD20,
            "description": "Recently started playing Pathfinder with a few friends and it was awesome! I would definitly like to try some D&D as well.",
            "color": "red"
        },
        "food": {
            "icon": faUtensils,
            "description": "I love to eat, usually unhealthy food like pizza, hamburgers and noodles. Trying to even it out by doing some sport.",
            "color": "green"
        },
        "traveling": {
            "icon": faEarthEurope,
            "description": "My dream is to travel to Tokyo and Quebec. I've traveled to Australia and California and I loved it!",
            "color": "yellow"
        },
        "music": {
            "icon": faMusic,
            "description": "Music helps me to keep my focus on my current activities, I love all kinds of music.",
            "color": "purple"
        }
    }

    const selectHobby = (hobby: keyof HobbyObject) => {
        setSelectedHobby(hobbiesObject[hobby]);
    }

    return (
        <>
            <div className="hobbies">
                <Rotation diameter={480} withLines={true} elements={[
                    <Shape type={"hexagon"} size={60} label={"Video"} extraClass={"orange"} action={() => selectHobby("video")}>
                        <FontAwesomeIcon icon={faVideo} />
                    </Shape>,
                    <Shape type={"hexagon"} size={60} label={"Gaming"} extraClass={"blue"} action={() => selectHobby("gaming")}>
                        <FontAwesomeIcon icon={faGamepad} />
                    </Shape>,
                    <Shape type={"hexagon"} size={60} label={"Roleplay"} extraClass={"red"} action={() => selectHobby("roleplay")}>
                        <FontAwesomeIcon icon={faDiceD20} />
                    </Shape>,
                    <Shape type={"hexagon"} size={60} label={"Food"} extraClass={"green"} action={() => selectHobby("food")}>
                        <FontAwesomeIcon icon={faUtensils} />
                    </Shape>,
                    <Shape type={"hexagon"} size={60} label={"Traveling"} extraClass={"yellow"} action={() => selectHobby("traveling")}>
                        <FontAwesomeIcon icon={faEarthEurope} />
                    </Shape>,
                    <Shape type={"hexagon"} size={60} label={"Music"} extraClass={"purple"} action={() => selectHobby("music")}>
                        <FontAwesomeIcon icon={faMusic} />
                    </Shape>
                ]} />
                <div className="selected-hobby">
                    <Shape type={"octogon"}>
                        <Shape type={"hexagon"} size={60} extraClass={selectedHobby.color}>
                            {selectedHobby.icon ? <FontAwesomeIcon icon={selectedHobby.icon} /> : ''}
                        </Shape>
                        <p>{selectedHobby.description}</p>
                    </Shape>
                </div>
            </div>
        </>
    );
}

type HobbyObject = {
    video: Hobby;
    gaming: Hobby;
    roleplay: Hobby;
    food: Hobby;
    traveling: Hobby;
    music: Hobby;
};

type Hobby = {
    icon: IconProp;
    description: string;
    color: string;
};