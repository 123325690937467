import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocation, faPlus, faMinus, faLink, faSplotch, faQuestion } from '@fortawesome/free-solid-svg-icons';

import Shape from '../common/Shape.tsx';
import Title from '../common/Title.tsx';
import Text from '../common/Text.tsx';

import { useSecret } from '../../utils/secretHook.tsx';

export default function TimelineItem({ experience }: Props) {
    const [displayShort, setDisplayShort] = useState(true);
    const [marginBottom, setMarginBottom]: any = useState(null);
    const { secret, setSecret } = useSecret();
    const descriptionRef = useRef<HTMLDivElement>(null);
    const listSvg = <svg width="10" height="10" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <polygon points="20,45 70,15 130,45 130,105 70,135 20,105"></polygon>
    </svg>;

    useEffect(() => {
        if (descriptionRef) {
            setTimeout(function () {
                const height = descriptionRef.current?.clientHeight;
                const margin = height ? (height - 20) + "px" : 0;
                setMarginBottom(margin);
            }, 450)
        }
    }, [displayShort]);

    const openLink = (link: string): void => {
        window.open(link, "_blank");
    }

    const unlockDisco = () => {
        if (secret.disco.unlocked) {
            setSecret({ ...secret, disco: { ...secret.disco, running: !secret.disco.running } });
        } else {
            setSecret({ ...secret, disco: { ...secret.disco, unlocked: true } });
        }
    }

    return (
        <div className="timeline-item">
            <div className="timeline-button" style={marginBottom !== null ? { marginBottom: marginBottom } : {}}>
                <Shape type={"hexagon"} size={100}>
                    <div className={experience.class}></div>
                </Shape>
                <p>{experience.date}</p>
            </div>
            <div className={`timeline-description ${displayShort ? "short" : "long"}`} ref={descriptionRef}>
                <Shape type="octogon">
                    <Title text={experience.title} withLines={true} />
                    <Shape type={"triangle"} size={30}></Shape>
                    <Text rawText={experience.dateDetailed} extraClass={"themed tiny center"} />
                    <div className="timeline-description-buttons">
                        <Shape type={"hexagon"} size={35} action={() => openLink(experience.location)} label={"Location"}>
                            <FontAwesomeIcon icon={faLocation} />
                        </Shape>
                        <Shape type={"hexagon"} size={35} action={() => setDisplayShort(!displayShort)} label={displayShort ? "More details" : "Less details"}>
                            <FontAwesomeIcon icon={displayShort ? faPlus : faMinus} />
                        </Shape>
                        <Shape type={"hexagon"} size={35} action={() => openLink(experience.site)} label={"Site"}>
                            <FontAwesomeIcon icon={faLink} />
                        </Shape>
                        {experience.special ?
                            <Shape type={"square"} size={35} action={() => unlockDisco()} label={secret.disco.unlocked ? 'Lets dance!' : 'Hidden in experiences!'} extraClass={secret.disco.unlocked ? 'unlocked' : ''}>
                                <FontAwesomeIcon icon={secret.disco.unlocked ? faSplotch : faQuestion} />
                            </Shape>
                            : ''}
                    </div>
                    <div className="timeline-description-content">{displayShort ?
                        <ul className={"fade-in-scale-up with-delay"}>
                            {
                                experience.short.map((label, index) => {
                                    return <li key={index}>
                                        {listSvg}
                                        {label}
                                    </li>;
                                })
                            }
                        </ul> :
                        <Text rawText={experience.long} extraClass={"fade-in-scale-up with-delay"} />}
                    </div>
                </Shape>
            </div>
        </div>
    );
}

type Props = {
    experience: experienceObject;
};

type experienceObject = {
    title: string;
    type: string;
    class: string;
    date: string;
    dateDetailed: string;
    site: string;
    location: string;
    short: string[];
    long: string;
    special?: boolean;
};