import React, { createContext, useContext, useState, ReactNode, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';

type SecretType = {
    secret: SecretObject;
    setSecret: React.Dispatch<React.SetStateAction<SecretObject>>;
};

type SecretObject = {
    music: SecretSubObject;
    disco: SecretSubObject;
    game: SecretSubObject;
};

type SecretSubObject = {
    unlocked: boolean;
    running: boolean;
    activated: boolean;
};

const Context = createContext<SecretType | null>(null);

const useGlobalHook = () => {
    const isInitialRender = useRef(true);
    const [secret, setSecret] = useState<SecretObject>({
        music: {
            unlocked: false,
            running: false,
            activated: false,
        },
        disco: {
            unlocked: false,
            running: false,
            activated: false,
        },
        game: {
            unlocked: false,
            running: false,
            activated: false,
        },
    });



    useEffect(() => {
        let cookieInitial = Cookies.get('secret');

        if (cookieInitial === undefined) {
            Cookies.set('secret', JSON.stringify(secret));
        } else {
            setSecret(JSON.parse(cookieInitial));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }

        if (Cookies.get('secret') !== JSON.stringify(secret)) {
            Cookies.set('secret', JSON.stringify(secret));
        }
    }, [secret]);

    return { secret, setSecret };
};

const Provider = ({ children }: { children: ReactNode }) => {
    const globalHookState = useGlobalHook();

    return (
        <Context.Provider value={globalHookState}>
            {children}
        </Context.Provider>
    );
};

const useSecret = () => {
    const context = useContext(Context);
    if (!context) {
        throw new Error('useSecret must be used within a SecretProvider');
    }
    return context;
};

type SecretKeys = keyof SecretObject;

export { useSecret, Provider as SecretProvider };
export type { SecretKeys };