import React from 'react';
import ReactDOM from 'react-dom/client';

import Main from './components/common/Main.tsx';

import './style/tailwind.css';
import './style/main.scss';

import { SecretProvider } from './utils/secretHook.tsx';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SecretProvider>
      <Main />
    </SecretProvider>
  </React.StrictMode>
);