import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlay, faPause, faVolumeMute, faVolumeLow, faForwardStep, faVolumeHigh, faVolumeOff, faChain } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import Shape from '../common/Shape.tsx';

import { useSecret } from '../../utils/secretHook.tsx';

export default function MusicPlayer() {
    const [play, setPlay] = useState(false);
    const [volume, setVolume] = useState(0.0625);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [history, setHistory]: any = useState([]);
    const { secret } = useSecret();
    const audioRef: any = useRef(null);
    const songs = [
        {
            song: require('../../static/music/Cupid.mp3'),
            name: 'Cupid',
            link: 'https://www.youtube.com/watch?v=6uvUTu716rU'
        },
        {
            song: require('../../static/music/LookDown.mp3'),
            name: 'Don\'t look down',
            link: 'https://www.youtube.com/watch?v=nJSdYlAFKqA'
        },
        {
            song: require('../../static/music/Ratatouille.mp3'),
            name: 'Ratatouille',
            link: 'https://www.youtube.com/watch?v=We3ht-BskBk'
        },
        {
            song: require('../../static/music/Summer.mp3'),
            name: 'Summer',
            link: 'https://www.youtube.com/watch?v=McEoTIqoRKk'
        },
        {
            song: require('../../static/music/Universe.mp3'),
            name: 'Universe',
            link: 'https://www.youtube.com/watch?v=6UkjnaELbWs'
        }
    ];

    useEffect(() => {
        setPlay(false);
        setCurrentIndex(-1);
    }, [secret.music.running]);

    useEffect(() => {
        if (currentIndex !== -1) {
            audioRef.current.addEventListener('ended', playNextSong);
            return () => {
                if (audioRef.current) {
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    audioRef.current.removeEventListener('ended', playNextSong);
                }
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentIndex]);

    if (!secret.music.running) {
        return <></>;
    }

    const playNewMusic = () => {
        const randomIndex = Math.floor(Math.random() * songs.length);
        const selectedSong = songs[randomIndex].song;
        setCurrentIndex(randomIndex);
        setPlay(true);
        setHistory([...history, randomIndex]);
        audioRef.current.src = selectedSong;
        audioRef.current.volume = volume;
        audioRef.current.play();
    };

    const playNextSong = () => {
        let newIndex = Math.floor(Math.random() * songs.length);

        while (currentIndex === newIndex || history.includes(newIndex)) {
            newIndex = Math.floor(Math.random() * songs.length);
        }

        const selectedSong = songs[newIndex].song;
        setCurrentIndex(newIndex);
        setPlay(true);

        if (history.length === songs.length - 1) {
            setHistory([]);
        } else {
            setHistory([...history, newIndex]);
        }

        audioRef.current.src = selectedSong;
        audioRef.current.volume = volume;
        audioRef.current.play();
    };

    const toggleMusic = () => {
        setPlay(!play);
        if (!play) {
            if (!audioRef.current.src) {
                playNewMusic();
            } else {
                audioRef.current.play();
            }
        } else {
            audioRef.current.pause();
        }
    }

    const handleVolume = (newVolume: number) => {
        setVolume(newVolume);

        if (audioRef.current) {
            audioRef.current.volume = newVolume;
        }
    }

    const volumeIcon = (): IconProp => {
        if (volume === 0) {
            return faVolumeMute;
        }
        if (volume <= 0.0625) {
            return faVolumeOff;
        }
        if (volume <= 0.125) {
            return faVolumeLow;
        }

        return faVolumeHigh;
    }

    return (
        <div className="music-player">
            <Shape type="hexagon" size={40} label={play ? "Pause" : "Play"} action={() => toggleMusic()}>
                <FontAwesomeIcon icon={play ? faPause : faPlay} />
            </Shape>
            <Shape type="hexagon" size={40} label={"Volume"} action={() => handleVolume((volume + 0.0625) % 0.25)}>
                <FontAwesomeIcon icon={volumeIcon()} />
            </Shape>
            <Shape type="octogon">
                <p>{currentIndex < 0 ? 'Press play!' : songs[currentIndex].name}</p>
            </Shape>
            <Link to={currentIndex < 0 ? '' : songs[currentIndex].link} target={currentIndex < 0 ? '' : '_blank'}>
                <Shape type="hexagon" size={40} label={"Song link"} action={() => console.log('open link')}>
                    <FontAwesomeIcon icon={faChain} />
                </Shape>
            </Link>
            <Shape type="hexagon" size={40} label={"Next"} action={() => playNextSong()}>
                <FontAwesomeIcon icon={faForwardStep} />
            </Shape>
            <audio ref={audioRef} />
        </div>
    );
}