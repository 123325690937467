import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faQuestion } from '@fortawesome/free-solid-svg-icons';

import Bar from '../components/skills/Bar';
import Title from '../components/common/Title';
import Shape from '../components/common/Shape';
import CenterLine from '../components/common/CenterLine';
import CenterChart from '../components/skills/CenterChart';

import { useSecret } from '../utils/secretHook';

export default function Skills() {
    const [selected, setSelected] = useState('all');
    const { secret, setSecret } = useSecret();
    const redirectToTechnologySite = (technology: string) => {
        let redirectionLink: string = '';

        switch (technology) {
            case 'laravel':
                redirectionLink = "https://laravel.com/";
                break;
            case 'maven':
                redirectionLink = "https://maven.apache.org/";
                break;
            case 'adonisjs':
                redirectionLink = "https://adonisjs.com/";
                break;
            case 'mysql':
                redirectionLink = "https://www.mysql.com/";
                break;
            case 'mariadb':
                redirectionLink = "https://mariadb.org/";
                break;
            case 'tailwind':
                redirectionLink = "https://tailwindcss.com/";
                break;
            case 'figma':
                redirectionLink = "https://www.figma.com/";
                break;
            default:
                redirectionLink = "";
                break;
        }

        if (redirectionLink) {
            window.open(redirectionLink, "_blank");
        }
    }

    const unlockGame = () => {
        if (secret.game.unlocked) {
            setSecret({ ...secret, game: { ...secret.game, running: !secret.game.running } });
        } else {
            setSecret({ ...secret, game: { ...secret.game, unlocked: true } });
        }
    }

    return (
        <div className="skills">
            <CenterLine />
            <CenterChart selected={selected} setSelected={setSelected} />
            <div className="skills-info">
                <div>
                    <div className='languages'>
                        <Title text='Language' withLines={true} />
                        <Bar percentage={90} label={"French"} color={"blue"} />
                        <Bar percentage={85} label={"English"} color={"green"} />
                        <Bar percentage={70} label={"Dutch"} color={"red"} />
                    </div>
                    <div className='technologies'>
                        <Title text='Technologies' withLines={true} />
                        <div className='wrapper'>
                            <Shape type="hexagon" size={70} action={() => redirectToTechnologySite('laravel')} label="Laravel"><div className='laravel'></div></Shape>
                            <Shape type="hexagon" size={70} action={() => redirectToTechnologySite('maven')} label="Maven"><div className='maven'></div></Shape>
                            <Shape type="hexagon" size={70} action={() => redirectToTechnologySite('adonisjs')} label="Adonis JS"><div className='adonisjs'></div></Shape>
                            <Shape type="hexagon" size={70} action={() => redirectToTechnologySite('mysql')} label="MySQL"><div className='mysql'></div></Shape>
                            <Shape type="hexagon" size={70} action={() => redirectToTechnologySite('mariadb')} label="Maria DB"><div className='mariadb'></div></Shape>
                            <Shape type="hexagon" size={70} action={() => redirectToTechnologySite('tailwind')} label="Tailwind CSS"><div className='tailwind'></div></Shape>
                            <Shape type="hexagon" size={70} action={() => redirectToTechnologySite('figma')} label="Figma"><div className='figma'></div></Shape>
                            <Shape type="hexagon" size={70} action={() => unlockGame()} label={secret.game.unlocked ? "[work in progress]" : "???"} extraClass={secret.game.unlocked ? 'unlocked' : ''}>
                                <FontAwesomeIcon icon={secret.game.unlocked ? faGamepad : faQuestion} />
                            </Shape>
                        </div>
                    </div>
                    <div className='soft-skills'>
                        <Title text='Soft skills' withLines={true} />
                        <Bar percentage={90} label={"Flexibility"} color={"yellow"} />
                        <Bar percentage={80} label={"Communication"} color={"orange"} />
                    </div>
                </div>
                <div>
                    <div className='programming'>
                        <Title text='Programming' withLines={true} />
                        <Bar percentage={95} label={"HTML"} color={"blue"} active={selected === 'frontend' || selected === 'all'} />
                        <Bar percentage={90} label={"(S)CSS"} color={"green"} active={selected === 'frontend' || selected === 'all'} />
                        <Bar percentage={85} label={"React"} color={"red"} active={selected === 'frontend' || selected === 'all'} />
                        <Bar percentage={80} label={"Typescript"} color={"yellow"} active={selected === 'frontend' || selected === 'all'} />
                        <Bar percentage={80} label={"SQL"} color={"purple"} active={selected === 'database' || selected === 'all'} />
                        <Bar percentage={80} label={"Database Infra"} color={"orange"} active={selected === 'database' || selected === 'all'} />
                        <Bar percentage={75} label={"PHP"} color={"blue"} active={selected === 'backend' || selected === 'all'} />
                        <Bar percentage={70} label={"Vue 3"} color={"green"} active={selected === 'frontend' || selected === 'all'} />
                        <Bar percentage={60} label={"Java"} color={"red"} active={selected === 'backend' || selected === 'all'} />
                        <Bar percentage={50} label={"Node"} color={"yellow"} active={selected === 'backend' || selected === 'all'} />
                    </div>
                </div>
            </div>
        </div>
    );
}