import React, { useRef, useEffect } from 'react';

import Text from '../common/Text';

export default function Bar({ percentage, label, color, active = true }: Props) {
    const waveRef = useRef(null);
    const randomStretch = Math.random() * (3 - 1) + 1;

    useEffect(() => {
        const svg: any = waveRef.current;
        let startPos = Math.floor(Math.random() * (0 - (-200) + 1)) - 200;

        const animateWave = () => {
            startPos++;
            svg.style.backgroundPosition = `${startPos}px 0`;
            requestAnimationFrame(animateWave);
        };

        const animationId = requestAnimationFrame(animateWave);

        return () => cancelAnimationFrame(animationId);
    }, []);

    return (
        <div className={`bar ${active ? 'active' : 'inactive'}`}>
            <div className="border">
                <div className="content">
                    <div style={{ width: percentage + '%' }}>
                        <div className={`liquid bg-${color}`}>
                            <Text>{label}</Text>
                            <svg ref={waveRef} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 60" width="100%" height="100%" style={{ background: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 400 60\' width=\'100%\' height=\'100%\'%3E%3Cpath fill=\'%2311111166\' d=\'M0 40 Q50 10 100 40 Q150 70 200 40 Q250 10 300 40 Q350 70 400 40 V0 H0z\' /%3E%3C/svg%3E")', transform: "scaleX(" + randomStretch + ")" }}></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 50" width="100%" height="100%" style={{ background: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 400 50\' width=\'100%\' height=\'100%\'%3E%3Cpath fill=\'%2311111133\' d=\'M0 40 Q50 10 100 40 Q150 70 200 40 Q250 10 300 40 Q350 70 400 40 V0 H0z\' /%3E%3C/svg%3E")', transform: "scaleX(" + randomStretch + ")" }}></svg>
                        </div>
                        <Text extraClass="text-right">{percentage + "%"}</Text>
                    </div>
                </div>
            </div>
        </div>
    );
}

type Props = {
    percentage: number;
    active?: boolean;
    label: string;
    color: string;
};