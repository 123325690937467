import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faYoutube, faGitlab } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import Shape from '../common/Shape.tsx';
import Rotation from '../common/Rotation.tsx';

export default function Contact() {
	const redirectToPhone = () => {
		const phoneNumber = '+33768135613';
		window.location.href = `tel:${phoneNumber}`;
	};
	const redirectToYoutube = () => {
		window.open('https://www.youtube.com/@sebastienbacs/featured', '_blank');
	};
	const redirectToLinkedIn = () => {
		window.open('https://www.linkedin.com/in/sebastien-bacs/', '_blank');
	};
	const redirectToGitLab = () => {
		window.open('https://gitlab.com/SebastienBacs', '_blank');
	};
	const redirectToGitHub = () => {
		window.open('https://github.com/SebastienBacs', '_blank');
	};
	const redirectToMail = () => {
		const mail = 'sebastien.bacs1@gmail.com';
		window.location.href = `mailto:${mail}`;
	};

	return (
		<div className='contact'>
			<Rotation
				withLines={false}
				diameter={400}
				turning={false}
				elements={[
					<Shape type={'triangle'} size={100} extraClass={'green'} action={() => redirectToPhone()}>
						<FontAwesomeIcon icon={faPhone} />
					</Shape>,
					<Shape type={'triangle'} size={100} extraClass={'orange'} action={() => redirectToGitLab()}>
						<FontAwesomeIcon icon={faGitlab} />
					</Shape>,
					<Shape type={'triangle'} size={100} extraClass={'red'} action={() => redirectToYoutube()}>
						<FontAwesomeIcon icon={faYoutube} />
					</Shape>,
					<Shape type={'triangle'} size={100} extraClass={'blue'} action={() => redirectToLinkedIn()}>
						<FontAwesomeIcon icon={faLinkedin} />
					</Shape>,
					<Shape type={'triangle'} size={100} action={() => redirectToGitHub()}>
						<FontAwesomeIcon icon={faGithub} />
					</Shape>,
					<Shape type={'triangle'} size={100} extraClass={'yellow'} action={() => redirectToMail()}>
						<FontAwesomeIcon icon={faEnvelope} />
					</Shape>,
				]}
			/>
		</div>
	);
}
