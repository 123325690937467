import React, { Dispatch, SetStateAction } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

import Shape from './Shape.tsx';

export default function SideOptions({ darkMode, setDarkMode, setNoTransition }: Props) {

    const toggleDarkMode = () => {
        setNoTransition(true);
        setDarkMode(!darkMode);

        setTimeout(() => {
            setNoTransition(false);
        }, 100);
    }

    const setLanguage = () => {
        console.log('coming soon!');
    }

    return (
        <div className='side-options'>
            <Shape size={40} type={"hexagon"} action={() => setLanguage()} label='Language' place='right'>
                EN
            </Shape>
            <Shape size={40} type={"hexagon"} action={() => toggleDarkMode()} label={darkMode ? 'Light mode' : 'Dark mode'} place='right'>
                <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
            </Shape>
        </div>
    );
}

type Props = {
    darkMode: boolean;
    setDarkMode: Dispatch<SetStateAction<boolean>>;
    setNoTransition: Dispatch<SetStateAction<boolean>>;
};