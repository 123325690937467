import React, { Dispatch, SetStateAction } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt, faFire, faInfo, faQuestion, faDownload, faPaperPlane, faMusic } from '@fortawesome/free-solid-svg-icons';

import { handleDownload } from '../../utils/randomUtils.tsx';
import Shape from '../common/Shape.tsx';
import Rotation from '../common/Rotation.tsx';

import { useSecret } from '../../utils/secretHook.tsx';

export default function CenterFigure({ current, setCurrent }: Props) {
	const { secret, setSecret } = useSecret();
	const CVFile = require('../../static/file/CV - Sebastien Bacs.pdf');

	const unlockMusic = () => {
		if (secret.music.unlocked) {
			setSecret({ ...secret, music: { ...secret.music, running: !secret.music.running } });
		} else {
			setSecret({ ...secret, music: { ...secret.music, unlocked: true } });
		}
	};

	return (
		<div className='me-container'>
			<div className='me'>
				<Shape type={'hexagon'} size={320} extraClass='falling-border'>
					<div className='shape hexagon picture'></div>
				</Shape>
				<Rotation
					diameter={200}
					turning={false}
					elements={[
						<Shape type={'circle'} size={38} label={'Description'} place={'top'} active={current === 'description'} action={() => setCurrent('description')}>
							<FontAwesomeIcon icon={faCommentAlt} />
						</Shape>,
						<Shape type={'circle'} size={38} label={'Hobbies'} place={'right'} active={current === 'hobbies'} action={() => setCurrent('hobbies')}>
							<FontAwesomeIcon icon={faFire} />
						</Shape>,
						<Shape
							type={'circle'}
							size={38}
							label={secret.music.unlocked ? 'Play music!' : '???'}
							place={'right'}
							action={() => unlockMusic()}
							extraClass={secret.music.unlocked ? 'unlocked' : ''}
						>
							<FontAwesomeIcon icon={secret.music.unlocked ? faMusic : faQuestion} />
						</Shape>,
						<Shape type={'circle'} size={38} label={'Download CV'} place={'bottom'} active={current === 'file'} action={() => handleDownload(CVFile)}>
							<FontAwesomeIcon icon={faDownload} />
						</Shape>,
						<Shape type={'circle'} size={38} label={'Extra info'} place={'left'} active={current === 'info'} action={() => setCurrent('info')}>
							<FontAwesomeIcon icon={faInfo} />
						</Shape>,
						<Shape type={'circle'} size={38} label={'Contact'} place={'left'} active={current === 'contact'} action={() => setCurrent('contact')}>
							<FontAwesomeIcon icon={faPaperPlane} />
						</Shape>,
					]}
				/>
			</div>
		</div>
	);
}

type Props = {
	current: string;
	setCurrent: Dispatch<SetStateAction<string>>;
};
