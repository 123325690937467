import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBirthdayCake, faBriefcase, faCar, faClock, faGlobe, faHouse } from '@fortawesome/free-solid-svg-icons';

import Shape from '../common/Shape.tsx';
import Text from '../common/Text.tsx';

export default function Info() {
	return (
		<div className='about-info'>
			<div className='about-info-left'>
				<Shape type={'octogon'}>
					<Text>Birth</Text>
					<hr />
					<Shape type={'hexagon'} size={40}>
						<FontAwesomeIcon icon={faBirthdayCake} />
					</Shape>
					<Text>20 January 1996</Text>
				</Shape>
				<Shape type={'octogon'}>
					<Text>Nationality</Text>
					<hr />
					<Shape type={'hexagon'} size={40} label={'Birth'}>
						<FontAwesomeIcon icon={faGlobe} />
					</Shape>
					<Text>Belgian</Text>
				</Shape>
				<Shape type={'octogon'}>
					<Text>Living</Text>
					<hr />
					<Shape type={'hexagon'} size={40}>
						<FontAwesomeIcon icon={faHouse} />
					</Shape>
					<Text>Haute Savoie</Text>
				</Shape>
			</div>
			<div className='about-info-right'>
				<Shape type={'octogon'}>
					<Text>Specialisation</Text>
					<hr />
					<Shape type={'hexagon'} size={40}>
						<FontAwesomeIcon icon={faBriefcase} />
					</Shape>
					<Text>Fullstack Dev</Text>
				</Shape>
				<Shape type={'octogon'}>
					<Text>License</Text>
					<hr />
					<Shape type={'hexagon'} size={40}>
						<FontAwesomeIcon icon={faCar} />
					</Shape>
					<Text>Driver B</Text>
				</Shape>
				<Shape type={'octogon'}>
					<Text>Experience</Text>
					<hr />
					<Shape type={'hexagon'} size={40}>
						<FontAwesomeIcon icon={faClock} />
					</Shape>
					<Text>5 years</Text>
				</Shape>
			</div>
		</div>
	);
}
