import React, { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

import Shape from '../common/Shape.tsx';

function NavLinkBlock({ label, place, setCurrentPageTitle, isCurrent, targetLink, icon }: Props) {
    const navigate = useNavigate();

    const navigateToLink = () => {
        setCurrentPageTitle(targetLink);
        navigate(`/${targetLink}`);
    }

    return (
        <div className={`link`}>
            <Shape type={"hexagon"} size={46} active={isCurrent} label={label} place={place} action={() => navigateToLink()}>
                <FontAwesomeIcon icon={icon} />
            </Shape>
        </div>
    );
}

type Props = {
    label: string;
    place: PlacesType;
    setCurrentPageTitle: Dispatch<SetStateAction<string>>;
    isCurrent: boolean;
    targetLink: string;
    icon: IconProp;
};

type PlacesType =
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end';

export default NavLinkBlock;