import React, { useEffect, useState } from 'react';

export default function CenterLine({ size = 'md' }: Props) {
    const [topPosition, setTopPosition]: any = useState(window.innerWidth > 1200 || size === 'md' ? 78 : 155 + 'px');

    const handleScroll = () => {
        const scrolled = window.scrollY;
        const navHeight = window.innerWidth > 1200 || size === 'md' ? 78 : 155;

        setTopPosition((navHeight - scrolled) + 'px');

        if (scrolled >= navHeight) {
            setTopPosition(0);
        }
    };

    const handleResize = () => {
        if (window.innerWidth <= 1200) {
            setTopPosition(155 + 'px');
            window.scrollTo(0, 0);
        } else if (window.innerWidth > 1200) {
            setTopPosition(78 + 'px');
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        if (size === 'lg') {
            window.addEventListener('scroll', handleScroll);
            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('scroll', handleScroll);
                window.removeEventListener('resize', handleResize);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`center-line-container ${size}`} style={{ paddingTop: topPosition }}>
            <div className="center-line"></div>
        </div>
    );
}

type Props = {
    size?: string;
};