import React from 'react';

export default function Title({ text, withMargin = true, withLines = false, size = 'md' }: Props) {
    const displayTitleText = () => {
        const firstLetter = text.charAt(0);
        const restOfString = text.slice(1);

        return <><span className={`fancy-text ${firstLetter.toLowerCase()}`}>{firstLetter.toUpperCase()}</span>{restOfString}</>;
    }

    return (
        <h1 className={`${size} ${withLines ? 'with-lines' : ''} ${!withMargin ? 'no-margin' : ''}`}>
            {displayTitleText()}
        </h1>
    );
}

type Props = {
    text: string;
    withMargin?: boolean;
    withLines?: boolean;
    size?: string;
};