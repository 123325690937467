import React from 'react';

export default function Rotation({ diameter, elements, turning = true, withLines = false }: Props) {
    const displayElements = (): JSX.Element[] => {
        return (
            elements.map((element, index) => {
                return (
                    <div className="rotation-element" key={index}>
                        <div className="counter-rotation-element">
                            {element}
                        </div>
                    </div>
                )
            })
        )
    }

    return (
        <div className={`rotation ${turning ? 'turn' : ''} elements-${elements.length}`} style={{ 'width': diameter + 'px', 'height': diameter + 'px' }}>
            <div className="rotation-container">
                {withLines ? <div className="rotation-circle">{displayElements()}</div> : displayElements()}
            </div>
        </div>
    );
}

type Props = {
    diameter: number;
    elements: JSX.Element[];
    turning?: boolean;
    withLines?: boolean;
};