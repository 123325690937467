import React from 'react';

import CenterLine from '../components/common/CenterLine.tsx';
import Timeline from '../components/experiences/Timeline.tsx';

export default function Experiences() {
    return (
        <div className="experiences">
            <CenterLine size={"lg"} />
            <Timeline />
        </div>
    );
}