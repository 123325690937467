import React from 'react';

import TimelineItem from './TimelineItem.tsx';
import experiences from './experiences.json';

export default function Timeline() {
    return (
        <div className="timeline">
            {
                experiences.map((experience, index) => {
                    return <TimelineItem key={index} experience={experience} />;
                })
            }
        </div>
    );
}