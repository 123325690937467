import React from 'react';

export default function Text({ children, rawText, extraClass = '' }: Props) {
    const displayRawText = () => {
        return (
            <p className={`text ${extraClass}`} dangerouslySetInnerHTML={{ __html: (rawText ?? 'x') }} />
        )
    }

    const displayNormalText = () => {
        return (
            <p className={`text ${extraClass}`}>{children}</p>
        )
    }

    return (
        <>
            {rawText ? displayRawText() : displayNormalText()}
        </>
    );
}

type Props = {
    children?: any;
    rawText?: string;
    extraClass?: string;
};