import React, { Dispatch, SetStateAction } from 'react';

import Shape from '../common/Shape';

export default function CenterChart({ selected, setSelected }: Props) {

    const isSelected = (skill: string) => {
        if (selected === 'all') {
            return '';
        }

        return selected === skill ? 'active' : 'inactive';
    }

    const changeFocus = (skill: string) => {
        if (selected === skill) {
            setSelected('all');
        } else {
            setSelected(skill);
        }
    }

    return (
        <div className="center-chart">
            <Shape type='circle' size={320} extraClass='falling-border'>
                <div className='inner-circle'>
                    <div className={`slice ${isSelected('database')}`} onClick={() => changeFocus('database')}>
                        <div className='background'>
                            <span>Database</span>
                        </div>
                    </div>
                    <div className={`slice ${isSelected('frontend')}`} onClick={() => changeFocus('frontend')}>
                        <div className='background'>
                            <span>Front-end</span>
                        </div>
                    </div>
                    <div className={`slice ${isSelected('backend')}`} onClick={() => changeFocus('backend')}>
                        <div className='background'>
                            <span>Back-end</span>
                        </div>
                    </div>
                </div>
            </Shape>
        </div>
    );
}

type Props = {
    selected: string;
    setSelected: Dispatch<SetStateAction<string>>;
};