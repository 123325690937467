import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Nav from '../../components/nav/Nav.tsx';
import Name from '../../components/nav/Name.tsx';
import About from '../../pages/About.tsx';
import Experiences from '../../pages/Experiences.tsx';
import Skills from '../../pages/Skills.tsx';
import Secret from '../../pages/Secret.tsx';
import MusicPlayer from './MusicPlayer.tsx';

import { useSecret } from '../../utils/secretHook.tsx';
import SideOptions from './SideOptions.tsx';

export default function Main() {
    const { secret } = useSecret();
    const isDiscoRunning = secret.disco.running;
    const [darkMode, setDarkMode] = useState(true);
    const [noTransition, setNoTransition] = useState(false);


    return (
        <div className={(darkMode ? 'dark' : 'light') + (isDiscoRunning ? ' disco' : '') + (noTransition ? ' no-transition' : '')}>
            <Router>
                <Nav />
                <Name />
                <SideOptions darkMode={darkMode} setDarkMode={setDarkMode} setNoTransition={setNoTransition} />
                <Routes>
                    <Route path="/" element={<About />} />
                    <Route path="/experiences" element={<Experiences />} />
                    <Route path="/skills" element={<Skills />} />
                    <Route path="/secret" element={<Secret />} />
                </Routes>
                <MusicPlayer />
            </Router>
        </div>
    );
}