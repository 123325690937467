import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser, faTimeline, faCogs, faEgg } from '@fortawesome/free-solid-svg-icons';

import NavLinkBlock from './NavLinkBlock.tsx';
import Shape from '../common/Shape.tsx';

export default function Nav() {
    const location = useLocation();
    const [open, setOpen] = useState(true);
    const [isClickable, setIsClickable] = useState(true);
    const clickDownSound = require('../../static/sound/clickDown.mp3');
    const clickUpSound = require('../../static/sound/clickUp.mp3');

    const currentActiveLink = () => {
        return location.pathname.replace("/", "");
    }

    const [currentPageTitle, setCurrentPageTitle] = useState(currentActiveLink);

    useEffect(() => {
        if (!isClickable) {
            const timeout = setTimeout(() => {
                setIsClickable(true);
            }, 550);

            return () => clearTimeout(timeout);
        }
    }, [isClickable]);

    const playSound = (soundName: string) => {
        const sound1 = new Audio(soundName);
        const sound2 = new Audio(soundName);
        const sound3 = new Audio(soundName);
        const sound4 = new Audio(soundName);
        sound1.play();

        setTimeout(() => {
            sound2.play();
        }, 80);
        setTimeout(() => {
            sound3.play();
        }, 160);
        setTimeout(() => {
            sound4.play();
        }, 240);
    };

    const handleToggle = () => {
        if (isClickable) {
            setOpen(!open);
            if (open) {
                playSound(clickDownSound);
            } else {
                playSound(clickUpSound);
            }
            setIsClickable(false);
        }
    };

    return (
        <nav className={`${open ? 'active' : ''}`}>
            <div className="toggler">
                <Shape type={"hexagon"} size={56} action={() => handleToggle()}>
                    <FontAwesomeIcon icon={faBars} />
                </Shape>
            </div>
            <div className="ornament">
                <div className="left-line"></div>
                <svg xmlns="http://www.w3.org/2000/svg" height="78" width="71">
                    <line x1="1" y1="37" x2="1" y2="58"></line>
                    <line x1="1" y1="58" x2="36" y2="77"></line>
                    <line x1="36" y1="77" x2="70" y2="58"></line>
                    <line x1="70" y1="58" x2="70" y2="37"></line>
                </svg>
                <div className="right-line"></div>
            </div>
            <div className='links'>
                <NavLinkBlock place={"left"} label={'About me'} targetLink={''} icon={faUser} setCurrentPageTitle={setCurrentPageTitle} isCurrent={currentPageTitle === ''} />
                <NavLinkBlock place={"left"} label={'Experiences'} targetLink={'experiences'} icon={faTimeline} setCurrentPageTitle={setCurrentPageTitle} isCurrent={currentPageTitle === 'experiences'} />
                <NavLinkBlock place={"right"} label={'Skills'} targetLink={'skills'} icon={faCogs} setCurrentPageTitle={setCurrentPageTitle} isCurrent={currentPageTitle === 'skills'} />
                <NavLinkBlock place={"right"} label={'Secret'} targetLink={'secret'} icon={faEgg} setCurrentPageTitle={setCurrentPageTitle} isCurrent={currentPageTitle === 'secret'} />
            </div>
        </nav >
    );
}