import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Name() {
    const navigate = useNavigate();

    return (
        <button className="name" onClick={() => navigate("/")}>
            <p className="desktop"><span className="fancy-text s">S</span>ebastien Bacs</p>
            <p className="desktop">Fullstack Developer</p>
            <p className="mobile"><span className="fancy-text s">S</span>. B.</p>
            <p className="mobile">Fullstack Dev.</p>
        </button>
    );
}