import React, { useState } from 'react';

import CenterLine from '../components/common/CenterLine.tsx';
import CenterFigure from '../components/about/CenterFigure.tsx';
import Description from '../components/about/Description.tsx';
import Hobbies from '../components/about/Hobbies.tsx';
import Info from '../components/about/Info.tsx';
import Contact from '../components/about/Contact.tsx';

export default function About() {
    const [current, setCurrent] = useState('description');
    const [initialLoad, setInitialLoad] = useState(false);

    setTimeout(function () {
        setInitialLoad(true);
    }, 1500);

    return (
        <div className="about">
            <CenterLine />
            <div className="about-container">
                <CenterFigure current={current} setCurrent={setCurrent} />

                <div className="info-container">
                    {current === 'description' && initialLoad ? <Description /> : ''}
                    {current === 'hobbies' ? <Hobbies /> : ''}
                    {current === 'info' ? <Info /> : ''}
                    {current === 'contact' ? <Contact /> : ''}
                </div>
            </div>
        </div>
    );
}