import React from 'react';
import { faGamepad, faSplotch, faMusic, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Shape from '../components/common/Shape';
import Rotation from '../components/common/Rotation';
import CenterLine from '../components/common/CenterLine';

import { useSecret } from '../utils/secretHook';
import type { SecretKeys } from '../utils/secretHook';

export default function Secret() {
    const { secret, setSecret } = useSecret();
    const isGameUnlocked = secret.game.unlocked;
    const isMusicUnlocked = secret.music.unlocked;
    const isDiscoUnlocked = secret.disco.unlocked;

    const activateSecret = (secretType: SecretKeys): void => {
        setSecret({ ...secret, [secretType]: { ...secret[secretType], activated: true, running: !secret[secretType].running } });
    }

    return (
        <div className="secret">
            <CenterLine />
            <div className="absolute center-triangle">
                <Shape type="triangle" size={320} extraClass='falling-border' />
                <Rotation diameter={220} turning={false} elements={[
                    <Shape type={"hexagon"} size={70} label={''} place={'top'} extraClass={isGameUnlocked ? 'unlocked' : ''} action={isGameUnlocked ? () => activateSecret('game') : undefined}>
                        <FontAwesomeIcon icon={isGameUnlocked ? faGamepad : faQuestion} />
                    </Shape>,
                    <Shape type={"circle"} size={70} label={''} place={'bottom'} extraClass={isMusicUnlocked ? 'unlocked' : ''} action={isMusicUnlocked ? () => activateSecret('music') : undefined}>
                        <FontAwesomeIcon icon={isMusicUnlocked ? faMusic : faQuestion} />
                    </Shape>,
                    <Shape type={"square"} size={70} label={''} place={'top'} extraClass={isDiscoUnlocked ? 'unlocked' : ''} action={isDiscoUnlocked ? () => activateSecret('disco') : undefined}>
                        <FontAwesomeIcon icon={isDiscoUnlocked ? faSplotch : faQuestion} />
                    </Shape>
                ]} />
            </div>
        </div>
    );
}